import {
  setCookie,
} from '../redux/features/actions'
import {
  getProfile,
} from '../redux/api/actions'

const setCookieAndLoadProfile = async ({ store, res, req }) => {
  /*
   * If we are on the server, copy the cookie to the redux store so we can use it for server side data fetching
   */
  if (req?.headers?.cookie) {
    store.dispatch(setCookie(req?.headers?.cookie))
  }

  let profile

  try {
    profile = await store.dispatch(getProfile())
  } catch (exception) {
    // User is not logged in
  }

  return profile
}

export default setCookieAndLoadProfile
