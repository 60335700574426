/* Spcieal action to reset all of redux state */
export const RESET = 'RESET'

export const SET_COOKIE = 'SET_COOKIE'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_CART = 'SET_CART'
export const SET_SHOP_FOR_CART = 'SET_SHOP_FOR_CART'
export const SET_MEAL_TYPE_FOR_CART = 'SET_MEAL_TYPE_FOR_CART'
export const SET_MEAL_DAY_FOR_CART = 'SET_MEAL_DAY_FOR_CART'
export const SET_ORDER_TYPE_FOR_CART = 'SET_ORDER_TYPE_FOR_CART'
export const SET_PICKUP_START_FOR_CART = 'SET_PICKUP_START_FOR_CART'
export const SET_DELIVERY_LOCATION_FOR_CART = 'SET_DELIVERY_LOCATION_FOR_CART'
export const ADD_MEAL_TO_CART = 'ADD_MEAL_TO_CART'
export const ADD_SIDE_TO_CART = 'ADD_SIDE_TO_CART'
export const CHANGE_QUANTITY_FOR_MEAL_IN_CART = 'CHANGE_QUANTITY_FOR_MEAL_IN_CART'
export const CHANGE_QUANTITY_FOR_SIDE_IN_CART = 'CHANGE_QUANTITY_FOR_SIDE_IN_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const SET_CANCELATION_SURVEY = 'SET_CANCELATION_SURVEY'
export const SHOW_DIALOG = 'SHOW_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const SET_DEFAULT_OFFER_CODE = 'SET_DEFAULT_OFFER_CODE'

export const reset = () => ({ type: RESET })

export const setCookie = cookie => ({
  cookie,
  type: SET_COOKIE,
})

export const setLocation = location => ({
  location,
  type: SET_LOCATION,
})

export const setCart = cart => ({ type: SET_CART, cart })
export const setShopForCart = shop => ({ type: SET_SHOP_FOR_CART, shop })
export const setMealTypeForCart = mealType => ({ type: SET_MEAL_TYPE_FOR_CART, mealType })
export const setMealDayForCart = mealDay => ({ type: SET_MEAL_DAY_FOR_CART, mealDay })
export const setOrderTypeForCart = orderType => ({ type: SET_ORDER_TYPE_FOR_CART, orderType })
export const setPickupStartForCart = pickupStartsAt => ({ type: SET_PICKUP_START_FOR_CART, pickupStartsAt })
export const setDeliveryLocationForCart = deliveryLocation => ({ type: SET_DELIVERY_LOCATION_FOR_CART, deliveryLocation })
export const addMealToCart = meal => ({ type: ADD_MEAL_TO_CART, meal })
export const addSideToCart = side => ({ type: ADD_SIDE_TO_CART, side })
export const changeQuantityForMealInCart = ({ mealId, quantity }) => ({ type: CHANGE_QUANTITY_FOR_MEAL_IN_CART, mealId, quantity })
export const changeQuantityForSideInCart = ({ sideId, quantity }) => ({ type: CHANGE_QUANTITY_FOR_SIDE_IN_CART, sideId, quantity })
export const clearCart = () => ({ type: CLEAR_CART })
export const setCancelationSurvey = cancelationSurvey => ({ type: SET_CANCELATION_SURVEY, cancelationSurvey })
export const showDialog = dialog => ({ type: SHOW_DIALOG, dialog })
export const closeDialog = () => ({ type: CLOSE_DIALOG })
export const setDefaultOfferCode = defaultOfferCode => ({ type: SET_DEFAULT_OFFER_CODE, defaultOfferCode })
